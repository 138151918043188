import axios from 'axios';
import {ICrudGetAllAction, ICrudPutAction} from 'react-jhipster';

import {FAILURE, REQUEST, SUCCESS} from 'app/shared/reducers/action-type.util';
import {IReportMenu} from "app/shared/model/reportMenu.model";
import {fromCents, fromPercent} from 'app/shared/util/money-utils';

export const ACTION_TYPES = {
  FETCH: 'reportSettings/FETCH',
  FETCH_BY_ID: 'reportSettings/FETCH_BY_ID',
  UPDATE: 'reportSettings/UPDATE',
  UPDATE_ROLE: 'reportSettings/UPDATE_ROLE',
  RESET: 'reportSettings/RESET',
  DELETE_REPORT:'DELETE_REPORT',
  UPDATE_DELETE_FLAG:'UPDATE_DELETE_FLAG'
};

const initialState = {
  loading: false,
  errorMessage: null,
  deleteReport:false,
  settings: [],
  reportSettings: {
    name: '',
    slug: '',
    reportUrl:''
  },
  settingsDetail:{
    paymentEditThreshold: 0,
    costKm: 0,
    flatDriverFee: 0,
    countryId: '0',
    countryName: '',
    paymentMethod: '',
    currencyConverter:0,
    babyAgeLimit: 0,
    motherDeliveryDateLimit:0,
    currencySymbol:''
  },
  updating: false,
  updateSuccess: false,
};

export type ReportSettingsState = Readonly<typeof initialState>;

// Reducer
export default (state: ReportSettingsState = initialState, action): ReportSettingsState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case REQUEST(ACTION_TYPES.UPDATE):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true,
      };

    case REQUEST(ACTION_TYPES.DELETE_REPORT):
        return {
          ...state
      };

    case FAILURE(ACTION_TYPES.FETCH):
    case FAILURE(ACTION_TYPES.UPDATE):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };
    case FAILURE(ACTION_TYPES.DELETE_REPORT):
      return {
        ...state
      };

    case SUCCESS(ACTION_TYPES.FETCH_BY_ID): {
      const data = action.payload.data
      return {
        ...state,
        settingsDetail: {
          ...data,
          costKm: fromCents(data.costKm),
          flatDriverFee: fromCents(data.flatDriverFee),
          paymentEditThreshold: fromPercent(data.paymentEditThreshold)
        },
      };
    }

    case SUCCESS(ACTION_TYPES.UPDATE_DELETE_FLAG):
      return {
        ...state,
        deleteReport:action.payload
      };


    case SUCCESS(ACTION_TYPES.DELETE_REPORT):
      return {
        ...state,
        deleteReport:true
      };


    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export const deleteReportDashboard= (reportId) => {
  const requestUrl = `api/delete-report-menu/${reportId}`;
  return {
    type: ACTION_TYPES.DELETE_REPORT,
    payload: axios.delete(requestUrl),
  };
};




export const updateReportSettings: ICrudPutAction<IReportMenu> = (reportSettings) => async dispatch => {
  const id = reportSettings.id;
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE,
    payload: axios.put('/api/edit-report-menu', reportSettings),
  });

  await dispatch({
    type: ACTION_TYPES.UPDATE_ROLE,
    payload: axios.put('/api/edit-report-menu-role', { reportMenuId:id,
    role:reportSettings.role}),
  });


  return result;
};

export const updateReportDeleteFlag = () => async dispatch => {


  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_DELETE_FLAG,
    payload:false,
  });
};

export const addReportSettings: ICrudPutAction<IReportMenu> = (reportSettings) => async dispatch => {
  const id = reportSettings.id;
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE,
    payload: axios.post('/api/add-report-menu', reportSettings),
  });
  return result;
};


export const reset = () => dispatch => {
  return dispatch({
    type: ACTION_TYPES.RESET
  });
};
