import axios from 'axios';
import {ICrudGetAllAction, ICrudPutAction} from 'react-jhipster';

import {FAILURE, REQUEST, SUCCESS} from 'app/shared/reducers/action-type.util';
import {IPermissionSettings} from "app/shared/model/permission-settings-model";
import {fromCents, fromPercent} from 'app/shared/util/money-utils';

export const ACTION_TYPES = {
  FETCH: 'permissionSettings/FETCH',
  FETCH_BY_ROLE: 'permissionSettings/FETCH_BY_ROLE',
  FETCH_ALL_PERMISSION:'permissionSettings/FETCH_ALL_PERMISSION',
  UPDATE: 'permissionSettings/UPDATE',
  RESET: 'permissionSettings/RESET',
};

const initialState = {
  loading: false,
  errorMessage: null,
  settings: [],
  permissions:[],
  settingsDetail:[],
  updating: false,
  updateSuccess: false,
};

export type PermissionSettingsState = Readonly<typeof initialState>;

// Reducer
export default (state: PermissionSettingsState = initialState, action): PermissionSettingsState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
  case REQUEST(ACTION_TYPES.FETCH_ALL_PERMISSION):
        return {
          ...state,
          errorMessage: null,
          updateSuccess: false,
          loading: true,
        };
    case REQUEST(ACTION_TYPES.UPDATE):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true,
      };
    case FAILURE(ACTION_TYPES.FETCH):
    case FAILURE(ACTION_TYPES.FETCH_ALL_PERMISSION):
    case FAILURE(ACTION_TYPES.UPDATE):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.FETCH): {

      return {
        ...state,
        settings: action.payload.data,
      };
    }


    case SUCCESS(ACTION_TYPES.FETCH_BY_ROLE): {
      const data = action.payload.data
      return {
        ...state,
        settingsDetail: action.payload.data,
      };
    }

    case SUCCESS(ACTION_TYPES.FETCH_ALL_PERMISSION): {
      return {
        ...state,
        permissions: action.payload.data,
      };
    }



    case SUCCESS(ACTION_TYPES.UPDATE):
      {
        const data = action.payload.data

        data.forEach((item)=>{
          item.costKm = fromCents(item.costKm);
          item.flatDriverFee = fromCents(item.flatDriverFee);
          item.paymentEditThreshold = fromPercent(item.paymentEditThreshold);

          return item;

        });
        return {
          ...state,
          updating: false,
          updateSuccess: true,
          settings: [
            ...data
          ],
        };
      }

    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

const apiUrl = 'api/watotocareSettings';
// Actions

export const getPermissionSettings: ICrudGetAllAction<PermissionSettingsState> = () => {
  const requestUrl = `${'api/view-all-rolepermission'}`;
  return {
    type: ACTION_TYPES.FETCH,
    payload: axios.get(requestUrl),
  };
};

export const getAllPermission: ICrudGetAllAction<PermissionSettingsState> = () => {
  const requestUrl = `${'api/view-all-permissions'}`;
  return {
    type: ACTION_TYPES.FETCH_ALL_PERMISSION,
    payload: axios.get(requestUrl),
  };
};

export const getPermissionSettingsByRole: ICrudGetAllAction<PermissionSettingsState> = (role) => {
  const requestUrl = `${'api/view-all-permissions-byrole'+'/'+role}`;
  return {
    type: ACTION_TYPES.FETCH_BY_ROLE,
    payload: axios.get(requestUrl),
  };
};

/*
export const getWatotoCareSettingsByCountryID = (id) => {
  const requestUrl = `${apiUrl+'/byCountryId/'+id}`;
  return {
    type: ACTION_TYPES.FETCH_BY_ID,
    payload: axios.get(requestUrl),
  };
};*/

export const updatePermissionSettings: ICrudPutAction<IPermissionSettings> = (permissionSettings) => async dispatch => {
  const role = permissionSettings.role;
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE,
    payload: axios.put('api/editrolepermission', permissionSettings),
  });
  return result;
};

export const reset = () => dispatch => {
  return dispatch({
    type: ACTION_TYPES.RESET
  });
};
