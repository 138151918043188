import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { IRootState } from 'app/shared/reducers';
import {
  getWatotoCareSettingsByCountryID
} from 'app/modules/administration/watotocare-settings/watotocare-settings.reducer';

function MetabaseReport(props: any) {
  const { watotocareSettings, userCountryId } = props;
  const [iframeUrl, setIframeUrl] = useState('');
  const [iframeHeight, setIframeHeight] = useState(window.innerHeight ? window.innerHeight : '100%');
  const [noReport, setNoReport] = useState(false);

  useEffect(() => {
    if (watotocareSettings.countryId) {
      if (watotocareSettings.metabaseReportUrl && watotocareSettings.metabaseReportUrl !== '') {
        setIframeUrl(watotocareSettings.metabaseReportUrl);
        setNoReport(false);
      } else {
        setNoReport(true);
      }
    }
  }, [watotocareSettings]);

  const updateIframeHeight = () => {
    setIframeHeight(window.innerHeight ? window.innerHeight : '100%');
  };

  useEffect(() => {
    window.addEventListener('resize', updateIframeHeight);
    props.getWatotoCareSettingsByCountryID(userCountryId);

    return () => {
      window.removeEventListener('resize', updateIframeHeight);
    };
  }, []);


  return (
    <div>
      {iframeUrl && iframeUrl !== '' ?
        <iframe
          src={iframeUrl}
          frameBorder="0"
          width={'100%'}
          height={iframeHeight}
          allowTransparency
        ></iframe>
        :
        !noReport && (
          <div>
            <h3>Loading...</h3>
          </div>
        )
      }
      {noReport &&
        <div>
          <h3>Metabase Report URL not set</h3>
          <p>Please contact the administrator to set the Metabase Report URL</p>
        </div>
      }
    </div>
  );
}

const mapStateToProps = ({ watotocareSettings, authentication }: IRootState) => ({
  watotocareSettings: watotocareSettings.settingsDetail,
  userCountryId: authentication.userCountryId
});

const mapDispatchToProps = {
  getWatotoCareSettingsByCountryID
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(MetabaseReport);
