import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Card, CardHeader, Col, Label, Row } from 'reactstrap';
import { hasAnyPermission } from 'app/shared/auth/permissions';
import { IRootState } from 'app/shared/reducers';
import { getEntitiesByMonth, getSettings as getOutcomeSettings } from './patient-outcome.reducer';
import { AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getAllByType as getCountries } from 'app/entities/region/region.reducer';

export interface IPatientOutcomeProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {
}

export const PatientOutcomeCalendar = (props: IPatientOutcomeProps) => {
  const { patientOutcomeList, loading, isAdmin, userCountryId, countries, outcomeSettings } = props;

  const [activeCountryId, setActiveCountryId] = useState(null);
  const [activeMonth, setActiveMonth] = useState(moment().format('YYYY-MM'));
  const [patientOutcomeMonth, setPatientOutcomeMonth] = useState(null);
  const [outcomeColors, setOutcomeColors] = useState({});

  useEffect(() => {
    props.getCountries();
  }, []);

  useEffect(() => {
    if (countries.length > 0 && userCountryId !== '') {
      const country = countries.find(c => c.id === userCountryId);
      if (country) {
        setActiveCountryId(country.id);
      }
    }
  }, [countries, userCountryId]);

  useEffect(() => {
    props.getOutcomeSettings(activeCountryId);
  }, [activeCountryId]);

  useEffect(() => {
    if (activeCountryId && activeMonth) {
      props.getEntitiesByMonth(activeCountryId, activeMonth);
    }
  }, [activeCountryId, activeMonth]);

  const regCountriesList = props.countries.filter((ele) => {
    return isAdmin ? 1 : ele.id === userCountryId;
  });

  useEffect(() => {
    if (props.outcomeSettings && props.outcomeSettings.length > 0) {
      const colors = {};
      props.outcomeSettings.forEach(setting => {
        // assign random color to each outcome
        colors[setting.callDay] = '#' + Math.floor(Math.random() * 16777215).toString(16);
      });
      setOutcomeColors(colors);
    }
  }, [outcomeSettings]);

  useEffect(() => {
    if (patientOutcomeList && patientOutcomeList.length > 0) {
      const patientOutcomeListDateHashMap = {};
      patientOutcomeList.forEach(patientOutcome => {
        patientOutcomeListDateHashMap[moment(patientOutcome.date).format('YYYY-MM-DD')] = patientOutcome.outcomeCounts;
      });

      // create 5 x 7 2D array for the month
      const month = [];
      const firstDay = moment(activeMonth).startOf('month');
      const lastDay = moment(activeMonth).endOf('month');
      const firstDayOfWeek = firstDay.day();
      const daysInMonth = lastDay.date();
      let day = 1;
      for (let i = 0; i < 5; i++) {
        const week = [];
        for (let j = 0; j < 7; j++) {
          if (i === 0 && j < firstDayOfWeek) {
            week.push(null);
          } else if (day <= daysInMonth) {
            const outcomeCounts = patientOutcomeListDateHashMap[moment(activeMonth).date(day).format('YYYY-MM-DD')] || null;
            if (outcomeCounts) {
              week.push({
                date: day,
                outcomeCounts
              });
            } else {
              week.push({
                date: day,
                outcomeCounts: []
              });
            }
            day++;
          } else {
            week.push(null);
          }
        }
        month.push(week);
      }
      setPatientOutcomeMonth(month);
    }
  }, [patientOutcomeList]);

  return (
    <div>
      <h2 id="patient-outcome-heading">
        Patient Outcomes Calendar View
      </h2>
      <br />
      <AvForm>
        {regCountriesList &&
          <AvGroup>
            <Label for="country-filter">Country</Label>
            <AvInput
              type="select"
              name="country-filter"
              id="country-filter"
              onChange={(event) => {
                setActiveCountryId(event.target.value);
              }}
              value={activeCountryId}
            >
              {regCountriesList.map((country, i) => (
                <option value={country.id} key={`country-${i}`}>
                  {country.name}
                </option>
              ))}
            </AvInput>
          </AvGroup>
        }

        <br />
        {/* Date Filter */}
        <Row>
          {/* Previous Month */}
          <Col md="4" className="text-left align-content-center">
            <FontAwesomeIcon icon={'arrow-left'} color={loading ? 'gray' : 'black'} className="hand" onClick={() => {
              if (!loading) {
                setActiveMonth(moment(activeMonth).subtract(1, 'month').format('YYYY-MM'));
              }
            }}
            />
          </Col>

          <Col md="4" className="text-center">
            <AvGroup>
              <Label for="patient-outcome-month">Select a Month</Label>
              <DatePicker
                selected={activeMonth ? moment(activeMonth, 'YYYY-MM').toDate() : null}
                popperPlacement="bottom"
                id="patient-outcome-month"
                showMonthYearPicker
                name="month"
                value={activeMonth}
                onChange={(date: any) => {
                  if (date) setActiveMonth(moment(date).format('YYYY-MM'));
                }}
              />
            </AvGroup>
          </Col>

          {/* Next Month */}
          <Col md="4" className="text-right align-content-center">
            <FontAwesomeIcon icon={'arrow-right'} color={loading ? 'gray' : 'black'} className="hand" onClick={() => {
              if (!loading) {
                setActiveMonth(moment(activeMonth).add(1, 'month').format('YYYY-MM'));
              }
            }}
            />
          </Col>
        </Row>
      </AvForm>

      {patientOutcomeList && patientOutcomeList.length > 0 ? (
        <Row className="pb-5 mr-0 ml-0">
          <Col md="3"></Col>
          {outcomeSettings && outcomeSettings.length > 0 && outcomeSettings.map((setting, i) => (
            <Col key={`setting-${i}`} md="3" className="d-inline-flex justify-content-center">
              <div style={{
                borderRadius: '100%',
                background: outcomeColors[setting.callDay],
                height: '25px',
                width: '25px'
              }}></div>
              &nbsp;{setting.callDay} Day Outcome Count
            </Col>
          ))}
          <Col md="3"></Col>
          <Col md="12" className="mt-3 pr-0 pl-0">
            <Card className="p-0">
              <CardHeader>
                <Row>
                  <Col className="text-center">Sun</Col>
                  <Col className="text-center">Mon</Col>
                  <Col className="text-center">Tue</Col>
                  <Col className="text-center">Wed</Col>
                  <Col className="text-center">Thu</Col>
                  <Col className="text-center">Fri</Col>
                  <Col className="text-center">Sat</Col>
                </Row>
              </CardHeader>
              <div>
                {patientOutcomeMonth && patientOutcomeMonth.length > 0 && patientOutcomeMonth.map((week, i) => (
                  <>
                    <Row key={`week-${i}`}>
                      {week.map((day, j) => (
                        <Col key={`day-${j}`} className="p-0">
                          {day && (
                            <div
                              className="hand"
                              style={{ border: '1px solid #f0f0f0', height: '100px', padding: '20px' }}
                            >
                              <h4 className="text-left" style={{ color: 'darkslategray' }}>{day.date}</h4>
                              <div className="float-right">
                                {day.outcomeCounts.map((outcome, k) => (
                                  <div
                                    key={`outcome-${k}`}
                                    className="d-inline-flex align-items-center justify-content-center rounded-circle m-1"
                                    style={{
                                      height: '25px',
                                      width: '25px',
                                      backgroundColor: outcomeColors[outcome.callDay],
                                      fontSize: '14px',
                                      color: 'white'
                                    }}
                                  >
                                    {outcome.callCount}
                                  </div>
                                ))}
                              </div>
                            </div>
                          )}
                        </Col>
                      ))}
                    </Row>
                  </>
                ))
                }
              </div>
            </Card>
          </Col>
        </Row>
      ) : (
        !loading && <div className="alert alert-warning">No PatientOutcomes found</div>
      )}
    </div>
  );
};

const mapStateToProps = ({ patientOutcome, authentication, region }: IRootState) => ({
  patientOutcomeList: patientOutcome.calendarEntities,
  loading: patientOutcome.loading,
  outcomeSettings: patientOutcome.settings,
  userCountryId: authentication.userCountryId,
  countries: region.countries,
  isAdmin: hasAnyPermission(authentication.account.roles, ['ADMIN'])
});

const mapDispatchToProps = {
  getEntitiesByMonth,
  getOutcomeSettings,
  getCountries
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(PatientOutcomeCalendar);
