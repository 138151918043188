import axios from 'axios';
import { ICrudDeleteAction, ICrudGetAction, ICrudPutAction, IPayloadResult } from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { FAILURE, REQUEST, SUCCESS } from 'app/shared/reducers/action-type.util';

import { defaultValue, IPatient } from 'app/shared/model/patient.model';
import { IPayload } from 'react-jhipster/src/type/redux-action.type';

export const ACTION_TYPES = {
  FETCH_PATIENT_LIST: 'patient/FETCH_PATIENT_LIST',
  FETCH_PATIENT: 'patient/FETCH_PATIENT',
  CREATE_PATIENT: 'patient/CREATE_PATIENT',
  UPDATE_PATIENT: 'patient/UPDATE_PATIENT',
  DELETE_PATIENT: 'patient/DELETE_PATIENT',
  RESET: 'patient/RESET'
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IPatient>,
  entity: defaultValue,
  updating: false,
  updateSuccess: false,
  totalItems: 0
};

export type PatientState = Readonly<typeof initialState>;

// Reducer

export default (state: PatientState = initialState, action): PatientState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_PATIENT_LIST):
    case REQUEST(ACTION_TYPES.FETCH_PATIENT):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true
      };
    case REQUEST(ACTION_TYPES.CREATE_PATIENT):
    case REQUEST(ACTION_TYPES.UPDATE_PATIENT):
    case REQUEST(ACTION_TYPES.DELETE_PATIENT):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true
      };
    case FAILURE(ACTION_TYPES.FETCH_PATIENT_LIST):
    case FAILURE(ACTION_TYPES.FETCH_PATIENT):
    case FAILURE(ACTION_TYPES.CREATE_PATIENT):
    case FAILURE(ACTION_TYPES.UPDATE_PATIENT):
    case FAILURE(ACTION_TYPES.DELETE_PATIENT):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload.response?.data?.title || action.payload.response?.data?.message || 'Oops! something went wrong'
      };
    case SUCCESS(ACTION_TYPES.FETCH_PATIENT_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10)
      };
    case SUCCESS(ACTION_TYPES.FETCH_PATIENT):
      return {
        ...state,
        loading: false,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.CREATE_PATIENT):
    case SUCCESS(ACTION_TYPES.UPDATE_PATIENT):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.DELETE_PATIENT):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {}
      };
    case ACTION_TYPES.RESET:
      return {
        ...state,
        loading: false,
        errorMessage: null,
        entities: [] as ReadonlyArray<IPatient>,
        entity: defaultValue,
        updating: false,
        updateSuccess: false,
        totalItems: 0
      };
    default:
      return state;
  }
};

const apiUrl = 'api/patients';

// Actions

export const getEntities: (countryId, filters?) => IPayload<IPatient> | IPayloadResult<IPatient> = (countryId, filters?) => async dispatch => {
  {
    let requestUrl = `${apiUrl}${`?countryId=${countryId}&page=${filters.activePage}&size=${filters.itemsPerPage}&sort=${filters.sort.sortType},${filters.sort.sortOrder}&cacheBuster=${new Date().getTime()}`}`;

    if (filters.wcId !== '' && filters.wcId) {
      requestUrl += `&wcId=${filters.wcId}`;
    }
    if (filters.patientName !== '' && filters.patientName) {
      requestUrl += `&patientName=${filters.patientName}`;
    }
    if (filters.patientBabyName !== '' && filters.patientBabyName) {
      requestUrl += `&patientBabyName=${filters.patientBabyName}`;
    }
    if (filters.mobileNumber !== '' && filters.mobileNumber) {
      requestUrl += `&mobileNumber=${filters.mobileNumber}`;
    }

    return await dispatch({
      type: ACTION_TYPES.FETCH_PATIENT_LIST,
      payload: axios.get<IPatient>(requestUrl)
    });
  }
};

export const getEntity: ICrudGetAction<IPatient> = id => {
  const requestUrl = `${apiUrl}/${id}?cacheBuster=${new Date().getTime()}`;
  return {
    type: ACTION_TYPES.FETCH_PATIENT,
    payload: axios.get<IPatient>(requestUrl)
  };
};

export const createEntity: ICrudPutAction<IPatient> = entity => async dispatch => {
  return await dispatch({
    type: ACTION_TYPES.CREATE_PATIENT,
    payload: axios.post(apiUrl, cleanEntity(entity))
  });
};

export const updateEntity: ICrudPutAction<IPatient> = entity => async dispatch => {
  return await dispatch({
    type: ACTION_TYPES.UPDATE_PATIENT,
    payload: axios.put(apiUrl, cleanEntity(entity))
  });
};

export const deleteEntity: ICrudDeleteAction<IPatient> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  return await dispatch({
    type: ACTION_TYPES.DELETE_PATIENT,
    payload: axios.delete(requestUrl)
  });
};

export const reset = () => dispatch => {
  return dispatch({
    type: ACTION_TYPES.RESET
  });
};
