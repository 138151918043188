import axios from 'axios';
import { ICrudGetAction, ICrudGetAllAction, ICrudPutAction, ICrudDeleteAction } from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { IRegion, defaultValue,IRegionDistrict } from 'app/shared/model/region.model';
import {RegionType} from "app/shared/model/enumerations/region-type.model";
import {IDispatchCenter} from 'app/shared/model/dispatch-center.model';
export const ACTION_TYPES = {
  FETCH_REGION_LIST: 'region/FETCH_REGION_LIST',
  FETCH_DISPATCH_CENTER:'dispatchCenter/FETCH_DISPATCH_CENTER',
  FETCH_DISPATCH_CENTER_BY_COUNTRY:'dispatchCenter/FETCH_DISPATCH_CENTER_BY_COUNTRY',
  FETCH_DISPATCH_CENTER_DETAIL:'dispatchCenter/FETCH_DISPATCH_CENTER_DETAIL',
  FETCH_ALL_REGIONS_BY_TYPE: 'region/FETCH_ALL_REGIONS_BY_TYPE',
  FETCH_ALL_REGIONS_BY_PARENT_AND_TYPE: 'region/FETCH_ALL_REGIONS_BY_PARENT_AND_TYPE',
  FETCH_REGION_LIST_BY_TYPE: 'region/FETCH_REGION_LIST_BY_TYPE',
  FETCH_REGION_LIST_NON_PAGINATED: 'region/FETCH_REGION_LIST_NON_PAGINATED',
  FETCH_REGION: 'region/FETCH_REGION',
  CREATE_REGION: 'region/CREATE_REGION',
  UPDATE_REGION: 'region/UPDATE_REGION',
  DELETE_REGION: 'region/DELETE_REGION',
  FETCH_ALL_COUNTRIES: 'commondata/FETCH_ALL_COUNTRIES',
  RESET: 'region/RESET',
};

const initialState = {
  loading: false,
  errorMessage: null,
  dipatchCenters:[],
  dipatchCenterDetail:[] as IDispatchCenter,
  entities: [] ,
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
  allEntitiesRefreshed: false,
  regionsByType: new Map<RegionType, IRegion[]>(),
  countries: [] as ReadonlyArray<IRegion>,
  registeredCountries: [] as ReadonlyArray<IRegion>,
  councils: [] as ReadonlyArray<IRegion>,
  dispatchCentersByCountry: []
};

export type DispatchCenterState = Readonly<typeof initialState>;

// Reducer

export default (state: DispatchCenterState = initialState, action): DispatchCenterState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_REGION_LIST):
    case REQUEST(ACTION_TYPES.FETCH_REGION_LIST_BY_TYPE):
    case REQUEST(ACTION_TYPES.FETCH_REGION):
    case REQUEST(ACTION_TYPES.FETCH_ALL_REGIONS_BY_TYPE):
    case REQUEST(ACTION_TYPES.FETCH_DISPATCH_CENTER_DETAIL):
    case REQUEST(ACTION_TYPES.FETCH_ALL_REGIONS_BY_PARENT_AND_TYPE):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case REQUEST(ACTION_TYPES.FETCH_DISPATCH_CENTER_BY_COUNTRY):
      return {
        ...state,
        errorMessage: null,
        dispatchCentersByCountry: [],
        updateSuccess: false,
        loading: true,
      };
    case REQUEST(ACTION_TYPES.FETCH_DISPATCH_CENTER):
      return {
        ...state,
        errorMessage: null,
        dipatchCenters:[],
        updateSuccess: false,
        loading: true,
    };


    case REQUEST(ACTION_TYPES.FETCH_REGION_LIST_NON_PAGINATED):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
        allEntitiesRefreshed: false,
      };
    case REQUEST(ACTION_TYPES.CREATE_REGION):
    case REQUEST(ACTION_TYPES.UPDATE_REGION):
    case REQUEST(ACTION_TYPES.DELETE_REGION):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true,
        allEntitiesRefreshed: false,
      };
    case FAILURE(ACTION_TYPES.FETCH_REGION_LIST):
    case FAILURE(ACTION_TYPES.FETCH_DISPATCH_CENTER):
    case FAILURE(ACTION_TYPES.FETCH_DISPATCH_CENTER_BY_COUNTRY):
    case FAILURE (ACTION_TYPES.FETCH_ALL_REGIONS_BY_TYPE):
    case FAILURE(ACTION_TYPES.FETCH_REGION_LIST_BY_TYPE):
    case FAILURE(ACTION_TYPES.FETCH_REGION_LIST_NON_PAGINATED):
    case FAILURE(ACTION_TYPES.FETCH_REGION):
    case FAILURE(ACTION_TYPES.CREATE_REGION):
    case FAILURE(ACTION_TYPES.UPDATE_REGION):
    case FAILURE(ACTION_TYPES.DELETE_REGION):
    case FAILURE(ACTION_TYPES.FETCH_DISPATCH_CENTER_DETAIL):
    case FAILURE(ACTION_TYPES.FETCH_ALL_REGIONS_BY_PARENT_AND_TYPE):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.FETCH_REGION_LIST):
        return {
          ...state,
          loading: false,
          entities: action.payload.data,
          totalItems: parseInt(action.payload.headers['x-total-count'], 10),
     };

    case SUCCESS(ACTION_TYPES.FETCH_DISPATCH_CENTER):
      return {
        ...state,
        loading: false,
        dipatchCenters: action.payload.data,

      };
    case SUCCESS(ACTION_TYPES.FETCH_DISPATCH_CENTER_BY_COUNTRY):
      return {
        ...state,
        dispatchCentersByCountry: action.payload.data,
        loading: false,
      };
      case SUCCESS(ACTION_TYPES.FETCH_DISPATCH_CENTER_DETAIL):
        return {
          ...state,
          loading: false,
          dipatchCenterDetail: action.payload.data,

        };


    case SUCCESS(ACTION_TYPES.FETCH_ALL_REGIONS_BY_TYPE):
      return {
        ...state,
        loading: false,
        countries: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.FETCH_ALL_REGIONS_BY_PARENT_AND_TYPE):
      return {
        ...state,
        loading: false,
        councils: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.FETCH_ALL_COUNTRIES):
        return {
          ...state,
          registeredCountries: action.payload.data
        };
    case SUCCESS(ACTION_TYPES.FETCH_REGION_LIST_NON_PAGINATED):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
        allEntitiesRefreshed: true,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10),
      };
    case SUCCESS(ACTION_TYPES.FETCH_REGION_LIST_BY_TYPE):
      return {
        ...state,
        loading: false,
        regionsByType: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.FETCH_REGION):
      return {
        ...state,
        loading: false,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.CREATE_REGION):
    case SUCCESS(ACTION_TYPES.UPDATE_REGION):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.DELETE_REGION):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {},
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

const apiUrl = 'api/regions';

// Actions

export const getEntities: ICrudGetAllAction<IRegion> = (page, size, sort) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_REGION_LIST,
    payload: axios.get<IRegion>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`),
  };
};


export const getCountries: () => void = () => async (dispatch, getState) =>{
  const requestUrl = 'api/regions/type/COUNTRY';
  await dispatch({
    type: ACTION_TYPES.FETCH_ALL_COUNTRIES,
    payload: axios.get<IRegion>(`${requestUrl}`),
  })

}

export const getAllByType = (type?) => {
  const requestUrl = `${apiUrl}/type23/${type ? type: 'COUNTRY'}`

  return {
    type: ACTION_TYPES.FETCH_ALL_REGIONS_BY_TYPE,
    payload: axios.get<IRegion>(`${requestUrl}?cacheBuster=${new Date().getTime()}`),
  }
};

export const getAllByParentAndType = (parentId, type) => {
  const requestUrl = `${apiUrl}/from-parent/${parentId}/${type}`

  return {
    type: ACTION_TYPES.FETCH_ALL_REGIONS_BY_PARENT_AND_TYPE,
    payload: axios.get<IRegion>(`${requestUrl}?cacheBuster=${new Date().getTime()}`),
  }
};

export const getAllEntities = () => {
  const requestUrl = `${apiUrl}/all`;
  return {
    type: ACTION_TYPES.FETCH_REGION_LIST_NON_PAGINATED,
    payload: axios.get<IRegion>(`${requestUrl}?cacheBuster=${new Date().getTime()}`),
  };
};



export const getRegionsByType = () => {
  const requestUrl = `${apiUrl}/all-grouped-by-type`;
  return {
    type: ACTION_TYPES.FETCH_REGION_LIST_BY_TYPE,
    payload: axios.get<Map<RegionType, IRegion[]>>(`${requestUrl}?cacheBuster=${new Date().getTime()}`),
  };
};

export const getEntity: ICrudGetAction<IRegion> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_REGION,
    payload: axios.get<IRegion>(requestUrl),
  };
};

export const getDispatchCenterDetail: ICrudGetAction<IRegion> = id => {
  const requestUrl = `${'api/getById'}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_DISPATCH_CENTER_DETAIL,
    payload: axios.get<IRegion>(requestUrl),
  };
};

export const getDispatchCenters = () => {
  const requestUrl = `${'api/getAllDispatchCenter'}`;
  return {
    type: ACTION_TYPES.FETCH_DISPATCH_CENTER,
    payload: axios.get<IRegionDistrict>(requestUrl),
  };
};

export const getDispatchCentersByCountry = (id: string) => {
  const requestUrl = `${'api/getAllDispatchCenter?countryId='}${id}`;
  return {
    type: ACTION_TYPES.FETCH_DISPATCH_CENTER_BY_COUNTRY,
    payload: axios.get<IRegionDistrict>(requestUrl),
  };
};


export const createEntity: ICrudPutAction<IRegionDistrict> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_REGION,
    payload: axios.post('api/saveOrUpdate', cleanEntity(entity)).then(response => {
    const putData={
      'districtIds' : entity.districtIds,
      'dispatchCenterId': response.data.id
  }
      return axios.put('api/updateRegions' ,cleanEntity(putData));
    }),
  });
  dispatch(getAllEntities());
  return result;
};

export const updateEntity: ICrudPutAction<IDispatchCenter> = entity => async dispatch => {

  const putObject = {
    'name':entity.name,
    'countryId':entity.countryId,
    'id':entity.id
  }
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_REGION,
    payload: axios.put('api/saveOrUpdate/'+entity.id, cleanEntity(putObject)).then(response => {
      const putData={
        'districtIds' : entity.districtIds,
        'dispatchCenterId': response.data.id
    }
        return axios.put('api/updateRegions' ,cleanEntity(putData));
      }),
  });
  return result;
};


export const deleteEntity: ICrudDeleteAction<IDispatchCenter> = id => async dispatch => {
  const requestUrl = `${'api/getById'}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_REGION,
    payload: axios.delete(requestUrl),
  });
  dispatch(getAllEntities());
  return result;
};

export const reset = () => dispatch => {
  return dispatch({
    type: ACTION_TYPES.RESET
  });
};
