import axios from 'axios';
import {ICrudDeleteAction, ICrudGetAction, ICrudGetAllAction, ICrudPutAction} from 'react-jhipster';

import {cleanEntity} from 'app/shared/util/entity-utils';
import {FAILURE, REQUEST, SUCCESS} from 'app/shared/reducers/action-type.util';

import {defaultValue, IRegion} from 'app/shared/model/region.model';
import {RegionType} from "app/shared/model/enumerations/region-type.model";

export const ACTION_TYPES = {
  FETCH_REGION_LIST: 'region/FETCH_REGION_LIST',
  FETCH_ALL_REGIONS_BY_TYPE: 'region/FETCH_ALL_REGIONS_BY_TYPE',
  FETCH_ALL_REGIONS_BY_PARENT_AND_TYPE: 'region/FETCH_ALL_REGIONS_BY_PARENT_AND_TYPE',
  FETCH_ALL_COUNCILS_BY_COUNTRY: 'region/FETCH_ALL_COUNCILS_BY_COUNTRY',
  FETCH_REGION_LIST_BY_TYPE: 'region/FETCH_REGION_LIST_BY_TYPE',
  FETCH_REGION_LIST_NON_PAGINATED: 'region/FETCH_REGION_LIST_NON_PAGINATED',
  FETCH_REGION: 'region/FETCH_REGION',
  CREATE_REGION: 'region/CREATE_REGION',
  UPDATE_REGION: 'region/UPDATE_REGION',
  DELETE_REGION: 'region/DELETE_REGION',
  RESET: 'region/RESET',
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IRegion>,
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
  allEntitiesRefreshed: false,
  regionsByType: new Map<RegionType, IRegion[]>(),
  countries: [] as ReadonlyArray<IRegion>,
  councils: [] as ReadonlyArray<IRegion>
};

export type RegionState = Readonly<typeof initialState>;

// Reducer

export default (state: RegionState = initialState, action): RegionState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_REGION_LIST):
    case REQUEST(ACTION_TYPES.FETCH_REGION_LIST_BY_TYPE):
    case REQUEST(ACTION_TYPES.FETCH_REGION):
    case REQUEST(ACTION_TYPES.FETCH_ALL_REGIONS_BY_TYPE):
    case REQUEST(ACTION_TYPES.FETCH_ALL_REGIONS_BY_PARENT_AND_TYPE):
    case REQUEST(ACTION_TYPES.FETCH_ALL_COUNCILS_BY_COUNTRY):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case REQUEST(ACTION_TYPES.FETCH_REGION_LIST_NON_PAGINATED):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
        allEntitiesRefreshed: false,
      };
    case REQUEST(ACTION_TYPES.CREATE_REGION):
    case REQUEST(ACTION_TYPES.UPDATE_REGION):
    case REQUEST(ACTION_TYPES.DELETE_REGION):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true,
        allEntitiesRefreshed: false,
      };
    case FAILURE(ACTION_TYPES.FETCH_REGION_LIST):
    case FAILURE(ACTION_TYPES.FETCH_ALL_REGIONS_BY_TYPE):
    case FAILURE(ACTION_TYPES.FETCH_REGION_LIST_BY_TYPE):
    case FAILURE(ACTION_TYPES.FETCH_REGION_LIST_NON_PAGINATED):
    case FAILURE(ACTION_TYPES.FETCH_REGION):
    case FAILURE(ACTION_TYPES.CREATE_REGION):
    case FAILURE(ACTION_TYPES.UPDATE_REGION):
    case FAILURE(ACTION_TYPES.DELETE_REGION):
    case FAILURE(ACTION_TYPES.FETCH_ALL_REGIONS_BY_PARENT_AND_TYPE):
    case FAILURE(ACTION_TYPES.FETCH_ALL_COUNCILS_BY_COUNTRY):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.FETCH_REGION_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10),
      };
    case SUCCESS(ACTION_TYPES.FETCH_ALL_REGIONS_BY_TYPE):
      return {
        ...state,
        loading: false,
        countries: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.FETCH_ALL_REGIONS_BY_PARENT_AND_TYPE):
    case SUCCESS(ACTION_TYPES.FETCH_ALL_COUNCILS_BY_COUNTRY):
      return {
        ...state,
        loading: false,
        councils: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.FETCH_REGION_LIST_NON_PAGINATED):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
        allEntitiesRefreshed: true,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10),
      };
    case SUCCESS(ACTION_TYPES.FETCH_REGION_LIST_BY_TYPE):
      return {
        ...state,
        loading: false,
        regionsByType: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.FETCH_REGION):
      return {
        ...state,
        loading: false,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.CREATE_REGION):
    case SUCCESS(ACTION_TYPES.UPDATE_REGION):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.DELETE_REGION):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {},
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

const apiUrl = 'api/regions';

// Actions

export const getEntities: ICrudGetAllAction<IRegion> = (page, size, sort) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_REGION_LIST,
    payload: axios.get<IRegion>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`),
  };
};

export const getAllByType = (type?) => {
  const requestUrl = `${apiUrl}/type/${type ? type : 'COUNTRY'}`

  return {
    type: ACTION_TYPES.FETCH_ALL_REGIONS_BY_TYPE,
    payload: axios.get<IRegion>(`${requestUrl}?cacheBuster=${new Date().getTime()}`),
  }
};

export const getAllByParentAndType = (parentId, type) => {
  const requestUrl = `${apiUrl}/from-parent/${parentId}/${type}`

  return {
    type: ACTION_TYPES.FETCH_ALL_REGIONS_BY_PARENT_AND_TYPE,
    payload: axios.get<IRegion>(`${requestUrl}?cacheBuster=${new Date().getTime()}`),
  }
};

export const getCouncilsByCountry = (countryId) => {
  const requestUrl = `${apiUrl}/councils-from-country/${countryId}`

  return {
    type: ACTION_TYPES.FETCH_ALL_COUNCILS_BY_COUNTRY,
    payload: axios.get<IRegion>(`${requestUrl}?cacheBuster=${new Date().getTime()}`),
  }
};

export const getAllEntities = () => {
  const requestUrl = `${apiUrl}/all`;
  return {
    type: ACTION_TYPES.FETCH_REGION_LIST_NON_PAGINATED,
    payload: axios.get<IRegion>(`${requestUrl}?cacheBuster=${new Date().getTime()}`),
  };
};

export const getRegionsByType = () => {
  const requestUrl = `${apiUrl}/all-grouped-by-type`;
  return {
    type: ACTION_TYPES.FETCH_REGION_LIST_BY_TYPE,
    payload: axios.get<Map<RegionType, IRegion[]>>(`${requestUrl}?cacheBuster=${new Date().getTime()}`),
  };
};

export const getEntity: ICrudGetAction<IRegion> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_REGION,
    payload: axios.get<IRegion>(requestUrl),
  };
};

export const createEntity: ICrudPutAction<IRegion> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_REGION,
    payload: axios.post(apiUrl, cleanEntity(entity)),
  });
  dispatch(getAllEntities());
  return result;
};

export const updateEntity: ICrudPutAction<IRegion> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_REGION,
    payload: axios.put(apiUrl, cleanEntity(entity)),
  });
  return result;
};

export const deleteEntity: ICrudDeleteAction<IRegion> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_REGION,
    payload: axios.delete(requestUrl),
  });
  dispatch(getAllEntities());
  return result;
};

export const reset = () => dispatch => {
  return dispatch({
    type: ACTION_TYPES.RESET
  });
};
