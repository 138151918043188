import axios from 'axios';
import { ICrudDeleteAction, ICrudGetAction, ICrudGetAllAction, ICrudPutAction } from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { FAILURE, REQUEST, SUCCESS } from 'app/shared/reducers/action-type.util';

import { defaultValue, IRoute } from 'app/shared/model/route.model';
import { fromCents } from 'app/shared/util/money-utils';

export const ACTION_TYPES = {
  SEARCH_ROUTES: 'route/SEARCH_ROUTES',
  FETCH_ROUTE_LIST: 'route/FETCH_ROUTE_LIST',
  FETCH_ROUTE: 'route/FETCH_ROUTE',
  CREATE_ROUTE: 'route/CREATE_ROUTE',
  UPDATE_ROUTE: 'route/UPDATE_ROUTE',
  DELETE_ROUTE: 'route/DELETE_ROUTE',
  RESET: 'route/RESET'
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IRoute>,
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
  deleteFailure: false,
  searchSuccess: false
};

export type RouteState = Readonly<typeof initialState>;

// Reducer

export default (state: RouteState = initialState, action): RouteState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.SEARCH_ROUTES):
    case REQUEST(ACTION_TYPES.FETCH_ROUTE_LIST):
    case REQUEST(ACTION_TYPES.FETCH_ROUTE):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        searchSuccess: false,
        loading: true
      };
    case REQUEST(ACTION_TYPES.CREATE_ROUTE):
    case REQUEST(ACTION_TYPES.UPDATE_ROUTE):
    case REQUEST(ACTION_TYPES.DELETE_ROUTE):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true
      };
    case FAILURE(ACTION_TYPES.SEARCH_ROUTES):
    case FAILURE(ACTION_TYPES.FETCH_ROUTE_LIST):
    case FAILURE(ACTION_TYPES.FETCH_ROUTE):
    case FAILURE(ACTION_TYPES.CREATE_ROUTE):
    case FAILURE(ACTION_TYPES.UPDATE_ROUTE):
    case FAILURE(ACTION_TYPES.DELETE_ROUTE):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        searchSuccess: false,
        errorMessage: action.payload.response?.data?.title || action.payload.response?.data?.message || 'Oops! something went wrong',
        deleteFailure: true
      };
    case SUCCESS(ACTION_TYPES.FETCH_ROUTE_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data.map((entity) => ({ ...entity, overridePrice: fromCents(entity.overridePrice) })),
        totalItems: parseInt(action.payload.headers['x-total-count'], 10)
      };
    case SUCCESS(ACTION_TYPES.SEARCH_ROUTES):
      console.log('fetchCount', action.payload.data.length);
      return {
        ...state,
        loading: false,
        searchSuccess: true,
        entities: action.payload.data.map((entity) => ({ ...entity, overridePrice: fromCents(entity.overridePrice) })),
        totalItems: parseInt(action.payload.headers['x-total-count'], 10)
      };
    case SUCCESS(ACTION_TYPES.FETCH_ROUTE): {
      const data = action.payload.data;
      return {
        ...state,
        loading: false,
        entity: {
          ...data,
          overridePrice: fromCents(data.overridePrice)
        }
      };
    }
    case SUCCESS(ACTION_TYPES.CREATE_ROUTE):
    case SUCCESS(ACTION_TYPES.UPDATE_ROUTE): {
      const data = { ...action.payload.data, overridePrice: fromCents(action.payload.data.overridePrice) };
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: data,
        entities: state.entities.map((value) => {
          if (value.id === data.id) {
            return data;
          }
          return value;
        })
      };
    }
    case SUCCESS(ACTION_TYPES.DELETE_ROUTE):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        searchSuccess: false,
        entity: {}
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

const apiUrl = 'api/routes';
const searchUrl = 'api/search/routes';

// Actions

export const searchEntitiesAxios = async (startId: string, endId: string, startType: string, endType: string, page, size, sort) => {
  const params = {
    ...startId ? { startId } : {},
    ...endId ? { endId } : {},
    ...startType ? { startType } : {},
    ...endType ? { endType } : {},
    ...{ page },
    ...size ? { size } : {},
    ...sort ? { sort } : {}
  };
  const queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
  const requestUrl = `${searchUrl}?${queryString}`;

  try {
    const response = await axios.get<IRoute[]>(requestUrl);
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.message || 'Error fetching routes');
  }
};

export const searchEntities = (startId: string, endId: string, startType: string, endType: string, page, size, sort) => {
  const params = {
    ...startId ? { startId } : {},
    ...endId ? { endId } : {},
    ...startType ? { startType } : {},
    ...endType ? { endType } : {},
    ...{ page },
    ...size ? { size } : {},
    ...sort ? { sort } : {}
  };
  const queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
  const requestUrl = `${searchUrl}?${queryString}`;

  return {
    type: ACTION_TYPES.SEARCH_ROUTES,
    payload: axios.get<IRoute>(requestUrl)
  };
};

export const getEntities: ICrudGetAllAction<IRoute> = (page, size, sort) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_ROUTE_LIST,
    payload: axios.get<IRoute>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`)
  };
};

export const getEntity: ICrudGetAction<IRoute> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_ROUTE,
    payload: axios.get<IRoute>(requestUrl)
  };
};

export const createEntity: ICrudPutAction<IRoute> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_ROUTE,
    payload: axios.post(apiUrl, cleanEntity(entity))
  });
  return result;
};

export const updateEntity: ICrudPutAction<IRoute> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_ROUTE,
    payload: axios.put(apiUrl, cleanEntity(entity))
  });
  return result;
};

export const deleteEntity: ICrudDeleteAction<IRoute> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_ROUTE,
    payload: axios.delete(requestUrl)
  });
  dispatch(getEntities());
  return result;
};

export const reset = () => dispatch => {
  return dispatch({
    type: ACTION_TYPES.RESET
  });
};
