import axios from 'axios';
import { ICrudGetAction, ICrudGetAllAction, ICrudPutAction, ICrudDeleteAction } from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { ICouncil, defaultValue } from 'app/shared/model/council.model';

export const ACTION_TYPES = {
  FETCH_COUNCIL_LIST: 'council/FETCH_COUNCIL_LIST',
  FETCH_COUNCIL: 'council/FETCH_COUNCIL',
  CREATE_COUNCIL: 'council/CREATE_COUNCIL',
  UPDATE_COUNCIL: 'council/UPDATE_COUNCIL',
  DELETE_COUNCIL: 'council/DELETE_COUNCIL',
  RESET: 'council/RESET',
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<ICouncil>,
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

export type CouncilState = Readonly<typeof initialState>;

// Reducer

export default (state: CouncilState = initialState, action): CouncilState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_COUNCIL_LIST):
    case REQUEST(ACTION_TYPES.FETCH_COUNCIL):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case REQUEST(ACTION_TYPES.CREATE_COUNCIL):
    case REQUEST(ACTION_TYPES.UPDATE_COUNCIL):
    case REQUEST(ACTION_TYPES.DELETE_COUNCIL):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true,
      };
    case FAILURE(ACTION_TYPES.FETCH_COUNCIL_LIST):
    case FAILURE(ACTION_TYPES.FETCH_COUNCIL):
    case FAILURE(ACTION_TYPES.CREATE_COUNCIL):
    case FAILURE(ACTION_TYPES.UPDATE_COUNCIL):
    case FAILURE(ACTION_TYPES.DELETE_COUNCIL):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.FETCH_COUNCIL_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10),
      };
    case SUCCESS(ACTION_TYPES.FETCH_COUNCIL):
      return {
        ...state,
        loading: false,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.CREATE_COUNCIL):
    case SUCCESS(ACTION_TYPES.UPDATE_COUNCIL):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.DELETE_COUNCIL):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {},
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

const apiUrl = 'api/councils';

// Actions

export const getEntities: ICrudGetAllAction<ICouncil> = (page, size, sort) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_COUNCIL_LIST,
    payload: axios.get<ICouncil>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`),
  };
};

export const getEntity: ICrudGetAction<ICouncil> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_COUNCIL,
    payload: axios.get<ICouncil>(requestUrl),
  };
};

export const createEntity: ICrudPutAction<ICouncil> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_COUNCIL,
    payload: axios.post(apiUrl, cleanEntity(entity)),
  });
  dispatch(getEntities());
  return result;
};

export const updateEntity: ICrudPutAction<ICouncil> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_COUNCIL,
    payload: axios.put(apiUrl, cleanEntity(entity)),
  });
  return result;
};

export const deleteEntity: ICrudDeleteAction<ICouncil> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_COUNCIL,
    payload: axios.delete(requestUrl),
  });
  dispatch(getEntities());
  return result;
};

export const reset = () => dispatch => {
  return dispatch({
    type: ACTION_TYPES.RESET
  });
};
