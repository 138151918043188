export interface IWatotoCareSettings {
  paymentEditThreshold: number,
  costKm: number,
  flatDriverFee: number,
  countryId: string,
  countryName?: string,
  paymentMethod?: string,
  currencyConverter?: number,
  currencySymbol?: string,
  babyAgeLimit?: number,
  motherDeliveryDateLimit?: number,
  id?: string,
  patientVisitLimit?: number,
  nodeAutoLogoff?: number,
  driverServiceFee?: number,
  driverWaitingFee?: number,
  physicianVisitFee?: number,
  dispatcherCutoffDatetime?: Date;
  dispatcherDaysBeforeSchedule?: number;
  outcomeDashboardMaxAge?: number;
  dispatcherDashboardMaxAge?: number;
  allowLowRiskVisit?: boolean;
  metabaseReportUrl?: string;
}

export const defaultValue: Readonly<IWatotoCareSettings> = {
  paymentEditThreshold: 0,
  costKm: 0,
  flatDriverFee: 0,
  countryId: '0',
  countryName: '',
  paymentMethod: '',
  currencyConverter: 0,
  currencySymbol: '',
  babyAgeLimit: 0,
  motherDeliveryDateLimit: 0,
  nodeAutoLogoff: 0,
  patientVisitLimit: 0,
  driverServiceFee: 0,
  driverWaitingFee: 0,
  physicianVisitFee: 0,
  dispatcherCutoffDatetime: null,
  dispatcherDaysBeforeSchedule: 0,
  metabaseReportUrl: '',
};
