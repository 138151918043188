import axios from 'axios';
import {ICrudDeleteAction, ICrudGetAction, ICrudPutAction, ICrudSearchAction, IPayloadResult} from 'react-jhipster';

import {cleanEntity} from 'app/shared/util/entity-utils';
import {FAILURE, REQUEST, SUCCESS} from 'app/shared/reducers/action-type.util';

import {defaultValue, IVillage} from 'app/shared/model/village.model';
import {IRegion} from "app/shared/model/region.model";
import {IPayload} from "react-jhipster/src/type/redux-action.type";

export const ACTION_TYPES = {
  FETCH_VILLAGE_SEARCH: 'village/FETCH_VILLAGE_SEARCH',
  FETCH_VILLAGE_LIST: 'village/FETCH_VILLAGE_LIST',
  FETCH_VILLAGE: 'village/FETCH_VILLAGE',
  CREATE_VILLAGE: 'village/CREATE_VILLAGE',
  UPDATE_VILLAGE: 'village/UPDATE_VILLAGE',
  DELETE_VILLAGE: 'village/DELETE_VILLAGE',
  FETCH_ALL_COUNTRIES: 'village/FETCH_ALL_COUNTRIES',
  RESET: 'village/RESET',
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IVillage>,
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  countries: [] as ReadonlyArray<IRegion>,
  updateSuccess: false,
};

export type VillageState = Readonly<typeof initialState>;

// Reducer

export default (state: VillageState = initialState, action): VillageState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_VILLAGE_SEARCH):
    case REQUEST(ACTION_TYPES.FETCH_VILLAGE_LIST):
    case REQUEST(ACTION_TYPES.FETCH_VILLAGE):
    case REQUEST(ACTION_TYPES.FETCH_ALL_COUNTRIES):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case REQUEST(ACTION_TYPES.CREATE_VILLAGE):
    case REQUEST(ACTION_TYPES.UPDATE_VILLAGE):
    case REQUEST(ACTION_TYPES.DELETE_VILLAGE):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true,
      };
    case FAILURE(ACTION_TYPES.FETCH_VILLAGE_SEARCH):
    case FAILURE(ACTION_TYPES.FETCH_VILLAGE_LIST):
    case FAILURE(ACTION_TYPES.FETCH_VILLAGE):
    case FAILURE(ACTION_TYPES.CREATE_VILLAGE):
    case FAILURE(ACTION_TYPES.UPDATE_VILLAGE):
    case FAILURE(ACTION_TYPES.DELETE_VILLAGE):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.FETCH_VILLAGE_SEARCH):
    case SUCCESS(ACTION_TYPES.FETCH_VILLAGE_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10),
      };
    case SUCCESS(ACTION_TYPES.FETCH_VILLAGE):
      return {
        ...state,
        loading: false,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.CREATE_VILLAGE):
    case SUCCESS(ACTION_TYPES.UPDATE_VILLAGE):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.DELETE_VILLAGE):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {},
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    case SUCCESS(ACTION_TYPES.FETCH_ALL_COUNTRIES):
      return {
        ...state,
        loading: false,
        countries: action.payload.data,
      };
    default:
      return state;
  }
};

const apiUrl = 'api/villages';

// Actions

export const getEntities: (page?, size?, sort?, countryId?) => IPayload<IVillage> | IPayloadResult<IVillage> = (page?, size?, sort?, countryId?) => async dispatch => {
  let requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;

  const countriesUrl = 'api/regions/type/COUNTRY';
  if (countryId) {
    requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}&countryId=${countryId}` : ''}`;
  } else {
    const countries = await dispatch({
      type: ACTION_TYPES.FETCH_ALL_COUNTRIES,
      payload: axios.get<IRegion>(`${countriesUrl}`)
    })
  }


  const villages = await dispatch({
    type: ACTION_TYPES.FETCH_VILLAGE_LIST,
    payload: axios.get<IVillage>(`${requestUrl}${sort ? '&' : '?'}`),
  });
  return villages
};


export const getVillagesByName: (searchString) => IPayload<IVillage> | IPayloadResult<IVillage> = (searchString) => {
  const requestUrl = `api/search/search-village-by-name/`;

  return {
    type: ACTION_TYPES.FETCH_VILLAGE_LIST,
    payload: axios.get<IVillage>(`${requestUrl}${searchString}`),
  }
};


export const getVillagesByCountry: (page?, size?, sort?, countryId?) => IPayload<IVillage> | IPayloadResult<IVillage> = (page?, size?, sort?, countryId?) => {
  const requestUrl = `${apiUrl}?countryId=${countryId}`;

  return {
    type: ACTION_TYPES.FETCH_VILLAGE_LIST,
    payload: axios.get<IVillage>(`${requestUrl}`),
  }
};

export const getEntity: ICrudGetAction<IVillage> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_VILLAGE,
    payload: axios.get<IVillage>(requestUrl),
  };
};

export const createEntity: ICrudPutAction<IVillage> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_VILLAGE,
    payload: axios.post(apiUrl, cleanEntity(entity)),
  });
  // dispatch(getEntities());
  return result;
};

export const getFilteredEntities: ICrudSearchAction<IVillage> = (search: string, size?: number) => {
  const page = 0;
  const defaultSize = 30;
  const requestUrl = `${apiUrl}?search=${encodeURIComponent(search)}&size=${size || defaultSize}&page=${page}`;
  return {
    type: ACTION_TYPES.FETCH_VILLAGE_SEARCH,
    payload: axios.get<IVillage>(`${requestUrl}}`),
  };
};

export const updateEntity: ICrudPutAction<IVillage> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_VILLAGE,
    payload: axios.put(apiUrl, cleanEntity(entity)),
  });
  return result;
};

export const deleteEntity: ICrudDeleteAction<IVillage> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_VILLAGE,
    payload: axios.delete(requestUrl),
  });
  dispatch(getEntities());
  return result;
};

export const reset = () => dispatch => {
  return dispatch({
    type: ACTION_TYPES.RESET
  });
};
